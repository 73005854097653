import { useAuth } from '@/hooks/use-auth'
import { useQueryBannerList } from '@/services/banner'
import { usePromotions } from '@/services/promotion'
// import FixedBanner from '@/ui/components/pages/home/banner/fixed-banner'
import isEmpty from 'lodash-es/isEmpty'
import Link from 'next/link'
import React, { FC, useEffect, useMemo } from 'react'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import type { Swiper as SwiperProps } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './index.module.scss'
import Placeholder, { LoadingImage } from './placeholder'

SwiperCore.use([Autoplay, Navigation, Pagination])

const Banner: FC = () => {
  const { authenticated, tokenLoaded } = useAuth()
  const { data, isLoading } = usePromotions(authenticated)
  const { trigger, data: bannerList, isMutating } = useQueryBannerList()

  useEffect(() => {
    void trigger()
  }, [trigger])

  const bannerListCom = () => {
    const handleJump = (url?: string) => {
      if (url) {
        window.open(url, '_blank')
      }
    }
    return bannerList!.map((item) => (
      <SwiperSlide key={item.id}>
        <div className="relative w-full h-full" data-testid="fixed-banner" onClick={() => handleJump(item.webUrls)}>
          <LoadingImage fill className="object-contain" src={item.webBannerUrl} alt="Promotion" priority />
        </div>
      </SwiperSlide>
    ))
  }

  const handleSwiper = (swiper: SwiperProps) => {
    swiper.autoplay.start()
  }

  const bannerLength = useMemo(() => (bannerList?.length || 0) + (data?.length || 0), [bannerList, data])

  if (tokenLoaded) {
    if (!isLoading && !isMutating) {
      if (data === undefined || bannerList === undefined) {
        return <Placeholder />
      } else {
        return (
          <Swiper
            // onSwiper={handleSwiper}
            navigation={bannerLength > 1}
            pagination={{
              clickable: true,
            }}
            autoplay={
              bannerLength > 1
                ? {
                    delay: 5000,
                    disableOnInteraction: false,
                  }
                : false
            }
            keyboard={true}
            loop={true}
            simulateTouch={false}
            modules={[Navigation, Pagination]}
            className={styles.swiper}
          >
            {data.map(({ code, webBannerUrl, webUrls }) => (
              <SwiperSlide key={code}>
                {isEmpty(webUrls) ? (
                  <LoadingImage src={webBannerUrl!} alt="Promotion" fill priority className="object-cover" />
                ) : (
                  <Link href={`/promotions/${code}`} target="_blank" className="block w-full h-full relative">
                    <LoadingImage src={webBannerUrl!} alt="Promotion" fill priority className="object-cover" />
                  </Link>
                )}
              </SwiperSlide>
            ))}
            {bannerListCom()}
          </Swiper>
        )
      }
    } else {
      // return <FixedBanner />
      return <Placeholder />
    }
  } else {
    return <Placeholder />
  }
}

const BannerWrapper: FC = () => (
  <div className="-mx-4 lg:-mx-18 overflow-hidden">
    <div className="flex items-center h-[calc((100vw-2rem)/2.04)] lg:h-[calc((100vw-6rem)/2.84)] min-w-[46rem] min-h-[22.5rem] lg:min-h-[25rem]">
      <Banner />
    </div>
  </div>
)

export default BannerWrapper
